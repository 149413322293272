import React from 'react'
import { Link } from "gatsby"
import { Heading1, ColorSpan } from 'components/text'
import IllustrationMobile from "../assets/illustration-mobile-development.inline.svg"
import IllustrationMobileAlt from "../assets/illustration-mobile-development-alt.inline.svg"
import IllustrationWeb from "../assets/illustration-web-development.inline.svg"
import IllustrationWebAlt from "../assets/illustration-web-development-alt.inline.svg"
import IllustrationAPI from "../assets/illustration-api-integrations.inline.svg"
import IllustrationAPIAlt from "../assets/illustration-api-integrations-alt.inline.svg"
import IllustrationDefine from "../assets/illustration-process-define.inline.svg"
import IllustrationDesign from "../assets/illustration-process-design.inline.svg"
import IllustrationDesignAlt from "../assets/illustration-process-design-alt.inline.svg"
import IllustrationDevelop from "../assets/illustration-process-development.inline.svg"
import IllustrationDeploy from "../assets/illustration-process-deploy.inline.svg"

/**
 * 
 * Site Metadata
 * - Encapsulation of site content.
 * - SVG references without complicating page components.
 * - Reusable components, call-to-actions, etc.
 * - Reusable functions for headings, images, etc.
 */
const Metadata = {
  Static: {
    faviconURL: '/img/favicon.ico',
    logoURLSmall: '/img/logo.png',
    contactEmail: 'hello@blueprintdigital.co',
    copyright: function () {
      const date = new Date()
      const year = date.getFullYear()
      return '© ' + year + ' BLUEPRINT DIGITAL, LLC • ALL RIGHTS RESERVED'
    },
    footerLinkList: function () {
      return (
        <div>
          <li><Link to="/about">About</Link></li>
          <li><Link to="/services">Services</Link></li>
          <li><Link to="/work">Work</Link></li>
          <li><Link to="/contact">Contact</Link></li>
        </div>
      )
    }
  },
  Colors: {
    siteBackgroundColor: '#000',
  },
  Navigation: {
    Links: ['about', 'services', 'process', 'work', 'contact']
  },
  Actions: {
    LearnMore: 'Learn more about us',
    GiveUsAShout: 'Give us a shout',
    FeaturedProjectLearnMore: 'View Project'
  },
  Home: {
    header: 'Blueprint Digital',
    subheader: 'Creating intuitive and robust software and business solutions',
  },
  About: {
    pageTitle: "About Us ― Blueprint Digital",
    metaTitle: "About Us ― Blueprint Digital",
    metaDescription: "Custom software designers and developers who build apps, websites, and cloud integrations based in Denver, Colorado.",
    renderSectionTitle: function () {
      return (<Heading1>We’re a <ColorSpan>passionate</ColorSpan > full-service team focused on delivering <ColorSpan> pixel-perfect</ColorSpan> quality from start to finish.</Heading1>)
    },
    header: "What we're all about",
    subheader: "Laying the blueprint for all your digital needs.",
    descriptionBlockOne: "From startup to enterprise we design and build software and workflows with all the features you need to accelerate and integrate your business in the digital world.",
    descriptionBlockTwo: "Our passion for tech and design meets our desire to transform your ideas into a robust and intuitive brand, application, website, or integration in the cloud.",
    descriptionBlockThree: "A full service agency, we are focused on delivering pixel-perfect quality in the design and development process and in every aspect of your project and relationship with you, from start to finish.",
    descriptionBlockFour: "We'd love to learn more about your project or idea!",
    callToAction: "Learn more about us",
    callToActionLink: "/about"
  },
  Portfolio: {
    pageTitle: "Our Work ― Blueprint Digital",
    metaTitle: "Our Work ― Blueprint Digital",
    metaDescription: "We are proud of the creative software solutions we’ve built for our clients. ",
    pageHeader: "Recent Projects",
    pageHeaderFeaturedProject: "Featured Project",
    callToActionLink: '/'
  },
  Process: {
    pageTitle: "Our Process ― Blueprint Digital",
    metaTitle: "Our Process ― Blueprint Digital",
    metaDescription: "Every project is different. That's why Blueprint uses a flexible approach adaptable to all project types.",
    header: "Our Recipe for Success",
    subheader: "Our process follows four essential phases: Define, Design, Develop, Deploy",
    items: [
      {
        title: "DEFINE",
        description: "Without a clear definition of the project, it is likely to fall victim to scope creep, missed expectations, and an unsatisfactory experience. A written definition of the scope of the project using a combination or user stories, narratives, requirements, and anti-requirements ensures everyone is on the same page and that the most important components have been prioritized.",
        illustration: function (className, styles) { return (<IllustrationDefine className={className} style={styles} />) },
      },
      {
        title: "DESIGN",
        description: "It is paramount to understand the branding, design and information architecture principles before writing code. These are a foundations of a great application. Blueprint Digital believes in user centered design that produces a simple and effective UX and UI. With a branding toolkit, design style, and UX in place - now we're ready to build your product quickly and efficiently without thrashing.",
        illustration: function (className, styles) { return (<IllustrationDesign className={className} style={styles} />) },
      },
      {
        title: "DEVELOP",
        description: "Our team combines the latest technologies and tools with dedication to our software development lifecycle to deliver your product’s features. We release dozens of updates on a regular cadence to our quality assurance team and you for testing and assurance we’re all creating the right solution together.",
        illustration: function (className, styles) { return (<IllustrationDevelop className={className} style={styles} />) },
      },
      {
        title: "DEPLOY",
        description: "Building software is one thing. Architecting software that is production-ready, scalable, and secure is another and something we take very seriously here at Blueprint Digital. Work with our experienced operations engineers to ensure we’re setting you up for success not only on launch day but also as you scale your business.",
        illustration: function (className, styles) { return (<IllustrationDeploy className={className} style={styles} />) },
      }
    ],
    callToActionLink: "/contact"
  },
  Services: {
    pageTitle: "Our Services ― Blueprint Digital",
    metaTitle: "Our Services ― Blueprint Digital",
    metaDescription: "iOS and Android mobile apps, website design and development, cloud integration, and product design services.",
    header: "Crafting stunning digital products",
    renderSectionTitle: function () {
      return (<Heading1 style={{ marginBottom: '40px' }}>From <ColorSpan>idea</ColorSpan > to <ColorSpan>finished product</ColorSpan> we offer custom software development services of all shapes and sizes.</Heading1>)
    },
    subheader: "No matter the problem, technology, or platform, we’ve got you covered.",
    all: [
      {
        illustrationTeaser: function (className, styles) {
          return (<IllustrationMobileAlt className={className} style={styles} />)
        },
        illustration: function (className, styles) {
          return (<IllustrationMobile className={className} style={styles} />)
        },
        header: 'Mobile App Design & Development',
        descriptionTeaser: 'Let\'s build the app that you\'ve been dreaming about, whether standalone or an app to complement the rest of your digital platform.',
        description: 'Determine your needs and build a standalone app or an app to complement the rest of your digital platform.',
        list: [
          'iOS, Android, React Native',
          'iPhone, iPad, & all Android devices',
          'App Store Optimization (ASO)',
          'User Experience (UX) & User Interface (UI) Design',
          'Database, Server, & API Development',
        ]
      },
      {
        illustrationTeaser: function (className, styles) {
          return (<IllustrationWebAlt className={className} style={styles} />)
        },
        illustration: function (className, styles) {
          return (<IllustrationWeb className={className} style={styles} />)
        },
        header: 'Web Design & Development',
        descriptionTeaser: 'Finally time to create your new website? Or update your old one? We\'re here to help land you in the right place.',
        description: "Establishing a strong digital presence is a necessity for businesses today. Whether you're building your first site or need to upgrade to a sleeker or more extensive site, we can help.",
        list: [
          'Custom Web Application Software',
          'Branded Websites & Microsites',
          'E-Commerce, Shopify',
          'SEO Reporting & Consulting'
        ]
      },
      {
        illustrationTeaser: function (className, styles) {
          return (<IllustrationAPIAlt className={className} style={styles} />)
        },
        illustration: function (className, styles) {
          return (<IllustrationAPI className={className} style={styles} />)
        },
        header: 'Cloud & API Integration',
        descriptionTeaser: "Modernizing your business processes with the cloud can make your operations run smoother and more efficiently, let us show you the way.",
        description: "Streamline your sales pipeline, accounting tools, and customer support software into one seamless workflow to avoid manual entry, double entry, data errors, and lost time.",
        list: [
          'Internet of Things (IoT)',
          'API Integrations & Workflow Solutions',
          'Migration Services',
          'SaaS Services',
          'Chat Bots, Artificial Intelligence, Microservices',
          'Mobile & Web Analytics & Business Intelligence'
        ]
      },
      {
        illustrationTeaser: function (className, styles) {
          return (<IllustrationDesignAlt className={className} style={styles} />)
        },
        illustration: function (className, styles) {
          return (<IllustrationDesign className={className} style={styles} />)
        },
        header: 'Product Design & Branding',
        descriptionTeaser: 'Give your products and brand the right identity so that customers are familiar with who you are and can distinguish what\'s yours from others.',
        description: "Function alone is not enough to make your product a success, superior design and exceptional user experience is a requirement. That's where we come in.",
        list: [
          'User Experience (UX) & User Interface (UI) Design',
          'Rapid Prototyping: Interactive & proof of concept design',
          'Design Sprints & User Research',
          'Data Visualizations, interactive visualizations, dashboards',
          'Workflow Design: Optimized user experiences, key interactions, information architecture'
        ]
      }
    ]
  },
  Contact: {
    pageTitle: "Contact Us ― Blueprint Digital",
    metaTitle: "Contact Us ― Blueprint Digital",
    metaDescription: "We want to learn more about your problem and your vision for a solution. Please fill our the contact form and we'll get in touch as soon as possible!",
    renderSectionTitle: function (color) {
      return (<Heading1 color={color}>Let’s get <ColorSpan>started</ColorSpan>, shall we?</Heading1>)
    },
    callToAction: "Tell us about your project",
    mapTitle: "Blueprint Digital HQ: Denver, Colorado",
    mapSourceURL: "https://www.openstreetmap.org/export/embed.html?bbox=-105.00735808163884%2C39.723236178680374%2C-104.97130919247867%2C39.74772371060869&amp;layer=hot",
    formName: "ContactForm",
    formTitle: "Get in Touch",
    formBody: "We are excited about the prospect to partner on your next project!",
    formBody2: "Given the opportunity to move forward, we are confident that our industry expertise, attention to detail, and creativity will deliver a successful end result for your business.",
    formSubmitText: "Submit",
    formFields: [
      {
        key: "name",
        placeholder: "Name (First and Last) *",
        type: "text",
        required: true,
      },
      {
        key: "email",
        placeholder: "Email Address *",
        type: "text",
        required: true,
      },
      {
        key: "companyDescription",
        placeholder: "Provide an elevator pitch about your company or idea. *",
        type: "textarea",
        required: true,
      },
      {
        key: "missing",
        placeholder: "What are you missing, and how do you see Blueprint helping with that? *",
        type: "textarea",
        required: true,
      },
      {
        key: "budget",
        description: "What is your budget? *",
        placeholder: "Please select an option.",
        type: "select",
        options: [
          { id: "small", text: "< $25K" },
          { id: "medium", text: "$50K - $100K" },
          { id: "large", text: "> $100K" }
        ],
        required: true,
      },
      {
        key: "timeframe",
        description: "How soon would you like to get started? *",
        placeholder: "Please select an option.",
        type: "select",
        options: [
          { id: "asap", text: "ASAP" },
          { id: "weeks", text: "In the next week or two" },
          { id: "months", text: "In the next month or two" }
        ],
        required: true,
      },
      {
        key: "targetDate",
        placeholder: "What is the target completion date for your project, if any? *",
        type: "text",
        required: true,
      },
      {
        key: "reference",
        description: "How did you hear about us?",
        placeholder: "Please select an option.",
        type: "select",
        options: [
          { id: "word-of-mouth", text: "Word of Mouth" },
          { id: "search-engine", text: "Search Engine (Google, Bing)" },
          { id: "other", text: "Other Source" }
        ],
        required: false,
      }
    ]
  },
  Footer: {
    navigationHeader: "Navigate",
    text: "Blueprint Digital is a software company that helps organizations design, build, and scale. Contact us today to put our innovative digital team to work for you.",
    callToAction: "Got a project? Let's talk."
  }
}

export default Metadata
import styled from 'styled-components'

/**
 * Section styled component for a "section" on a page.
 */
const Section = styled.section`
  position: relative;
  .particles {
      position: absolute;
      width: 100%;
      height: 100%;
  }
  background-image: url(${props => props.backgroundImage});
  background-size: cover;
  background-repeat: no-repeat;
`
export default Section
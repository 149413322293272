import React from 'react'
import handleViewport from 'react-in-viewport'
import 'animate.css/animate.compat.css'

class AnimationContainerInterface extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            inViewport: false,
            animationComplete: false,
            classChanged: false,
        }
    }

    componentDidUpdate() {
        if (this.state.inViewport !== this.props.inViewport && !this.state.animationComplete) {
            this.setState({ inViewport: this.props.inViewport })
            this.changeClass()
            this.setState({ animationComplete: true })
        }
    }

    componentDidMount() {
        this.changeClass()
    }

    changeClass() {
        const { delay } = this.props
        setTimeout(() => { this.setState({ classChanged: true }); }, delay);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.state.classChanged ? false : true
    }

    render() {
        const { children, animation, id, height } = this.props
        const className = this.state.classChanged ? `animated ${animation}` : ""
        return (
            <div className={{ className }} style={{ opacity: this.state.classChanged ? 1 : 0, height: height ? height : "auto" }} id={id} >
                {children}
            </div>
        );
    }
}
const AnimationContainer = handleViewport(AnimationContainerInterface);
export default AnimationContainer